import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { NovidadesService } from "src/app/shared/api-services/novidades.service";
import { RouterService } from "src/app/shared/services/router-service.service";
import { PopupDetalhesNovidadeComponent } from "../popup-detalhes-novidade/popup-detalhes-novidade.component";

@Component({
  selector: "app-novidades-notificacao",
  templateUrl: "./novidades-notificacao.component.html",
  styleUrls: ["./novidades-notificacao.component.scss"],
})
export class NovidadesNotificacaoComponent implements OnInit {
  @Output() novidadesExibidas = new EventEmitter<boolean>();
  @ViewChild("popupDetalhes") popupDetalhes: PopupDetalhesNovidadeComponent;

  toastKey = "NOVIDADES_NOTIFICACAO";
  body = document.body;

  constructor(private novidadeService: NovidadesService, private routerService: RouterService) {}

  ngOnInit(): void {
    this.temNovidades();
  }

  temNovidades() {
    this.novidadeService.verificarNovidadesNaoLidas().subscribe(
      (r) => {
        if (r) {
          this.popupDetalhes.novidadeCompleto = r;
          this.popupDetalhes.open();
          this.novidadesExibidas.emit(true);
        } else {
          this.novidadesExibidas.emit(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  notificacaoLida() {
    const qtdNaoLido = this.novidadeService.getQtdNaoLidas().value;
    if (qtdNaoLido <= 1) {
      this.novidadeService.setNovidadesVisualizadas(this.novidadeService.getQtdLidas() + 1);
    } else {
      this.routerService.toNovidades();
    }
  }
}
